import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPlay } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

import TechGraph from '../../components/TechGraph';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/Home.css';

const HomePage = () => {
    return (
        <div
            style={{
                backgroundImage: 'url(/home.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: '100vh',
                color: 'white',
            }}
        >
            <Helmet>
                <title>Cheatsheet++ | 600+ Software Development Cheat Sheets & Tutorials</title>
                <meta name="description" content="Cheatsheet++ offers a vast collection of free software development cheat sheets, tutorials, and historical context to enhance your learning. Join now and start improving your coding skills!" />
                <meta name="keywords" content="cheat sheets, software development, coding tutorials, programming, learning, developer resources, free educational content" />
                <meta property="og:title" content="Cheatsheet++ | Free Software Development Cheat Sheets & Tutorials" />
                <meta property="og:description" content="Discover a comprehensive collection of cheat sheets and tutorials for software developers. Enhance your coding skills with Cheatsheet++." />
                <meta property="og:image" content="/og-image.jpg" />
                <meta property="og:url" content="https://www.cheatsheet-plus-plus.com" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Cheatsheet++ | Free Software Development Cheat Sheets & Tutorials" />
                <meta name="twitter:description" content="Cheatsheet++ offers a vast collection of free software development cheat sheets, tutorials, and historical context to enhance your learning." />
                <meta name="twitter:image" content="/twitter-image.jpg" />

                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6060537356298204"
                    crossOrigin="anonymous"></script>
            </Helmet>
            <div className="container">
                <header style={{ textAlign: 'center', padding: '0px', backgroundColor: 'rgba(0, 0, 0, 0.0)' }}>
                    <h2 style={{ color: '#003860', marginTop: '0px' }}>A Vast Collection of Cheat Sheets and Tutorials</h2>
                    <p style={{ fontSize: 'larger' }}>
                        Access over 600 cheat sheets and 18,000 tutorials. Enhance your software development skills with our free and regularly updated resources.
                    </p>                   
                </header>
                <main>
                    <section className="value-proposition">
                        <h2 style={{ color: '#003860', margin: '0px' }}>Cheatsheet++</h2>
                        <p style={{ textAlign: 'left', fontSize: 'larger' }}>
                            Cheatsheet++ is dedicated to providing <strong>FREE</strong> educational content for software developers. Our searchable cheat sheets, in-depth tutorials, and historical insights make learning more effective and efficient.
                        </p>
                        <Link to="/topics">
                            <button className="btn btn-primary" aria-label="Start Learning Now">
                                Get Started – Access 600+ Resources Instantly!
                                <FontAwesomeIcon icon={faPlay} style={{ marginLeft: '10px' }} />
                            </button>
                        </Link>
                    </section>

                    <section className="technology graph">
                        <TechGraph style={{padding: '50px' }}/>
                    </section>

                    <section className="stats">
                        <div className="row">
                            {/* <div className="col-md-4">
                                <div className="card" style={{ height: '360px' }}>
                                    <img src="../cheatsheet.jpg" className="card-img-top" alt="Cheat Sheets and Subtopics" />
                                    <div className="card-body">
                                        <h3 className="card-title">600+ Cheat Sheets</h3>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-8">
                                <div className="card" style={{ height: 'auto', textAlign: 'left', padding: '20px' }}>
                                    <h2>Why Cheat Sheets Are Essential for Developers</h2>
                                    <p>
                                        Cheat sheets are popular because they provide developers with a quick and powerful way to grasp and retain complex information. 
                                        In a fast-paced industry where new tools and frameworks emerge constantly, having a reliable reference guide is invaluable. 
                                    </p>
                                    <p>
                                        At CheatSheet++, cheat sheets are more than just quick references—they are learning accelerators. 
                                        We offer interactive, customizable, and up-to-date cheat sheets designed to help you master new technologies and enhance problem-solving skills efficiently.
                                    </p>
                                    <p>
                                        By focusing on core principles and best practices, CheatSheet++ enables developers to stay sharp, solve problems faster, 
                                        and keep pace with the ever-evolving tech landscape.
                                    </p>

                                    <Link to="/topics" style={{textAlign: 'right'}}>
                                        <button className="btn btn-primary" aria-label="Start Learning Now">
                                            Get Started
                                            <FontAwesomeIcon icon={faPlay} style={{ marginLeft: '10px' }} />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card" style={{ height: 'auto', padding: '20px' }}>
                                    <h3 className="card-title">Benefits of Cheat Sheets</h3>
                                    <ul style={{ fontSize: 'smaller', textAlign: 'left' }}>
                                        <li>Condensed Information</li>
                                        <li>Quick Reference</li>
                                        <li>Enhanced Retention</li>
                                        <li>Time Efficiency</li>
                                        <li>Focus on Key Points</li>
                                        <li>Reduced Cognitive Load</li>
                                        <li>Improved Organization</li>
                                        <li>Boosts Confidence</li>
                                        <li>Encourages Active Learning</li>
                                        <li>Versatile Learning Tool</li>
                                        <li>Supports Exam Preparation</li>
                                        <li>Encourages Regular Review</li>
                                        <li>Enhances Problem-Solving Skills</li>
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                                <div className="card" style={{ height: '360px' }}>
                                    <img src="../tutorial.jpg" className="card-img-top" alt="Tutorials" />
                                    <div className="card-body">
                                        <h3 className="card-title">18,000+ Tutorials</h3>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </section>
                    
                    <section className="coming-soon">
                        <h2>Almost ready</h2>
                        <div className="row">
                            {/* <div className="col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">Flash Cards</h3>
                                        <p className="card-text">Cross-Topic Flash Cards for Better Learning</p>
                                        <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '10px' }} />
                                        <a href="mailto:suggestions-fc@cheatsheet-plus-plus.com" style={{ fontSize: 'smaller' }}>Send us your suggestions to prioritize this feature</a>
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-md-8">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">Master Your Tech Interviews</h3>
                                        <p className="card-text">
                                            Stay ahead by sharpening your knowledge with essential coding and technical questions. Whether you're preparing for an interview or aiming to refine your problem-solving skills, our curated questions will help you feel confident and ready for any challenge.
                                        </p>
                                        {/* <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '10px' }} />
                                        <a href="mailto:feedback-QA@cheatsheet-plus-plus.com" style={{ fontSize: 'smaller' }}>Share your feedback to help us improve this section</a> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">Interview Q&A</h3>
                                        <p className="card-text">Prepare with Common Interview Questions and Answers</p>
                                        <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '10px' }} />
                                        <a href="mailto:suggestions-QA@cheatsheet-plus-plus.com" style={{ fontSize: 'smaller' }}>Send us your suggestions to prioritize in this feature</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <hr />
                    <div className="card card-text p-10" style={{padding: '20px', backgroundColor: '#eee'}}>
                        <h3 className="card-title">Are you the kind of developer who thrives on knowing every technology, from front-end to back-end, databases to DevOps?</h3>
                        <p style={{fontSize: 'large', color: '#000'}}>
                            At CheatSheet++, we’ve got you covered with cheat sheets that span the entire tech ecosystem. From mastering languages like JavaScript, Python, and Rust to diving into the depths of frameworks, cloud services, and CI/CD tools—this is your playground to explore and conquer.
                        </p>
                        <br />
                        <h3 className="card-title">Love knowing obscure facts across different technologies?</h3>
                        <p style={{fontSize: 'large', color: '#000'}}>
                            Whether it’s the history of programming languages, fun facts about cloud services, or the quirks of frameworks and databases - CheatSheet++ also satisfies the tech trivia enthusiast in you. Explore deep technical knowledge, impress your peers with insights, and stay sharp across every corner of the tech world.
                        </p>
                        <Link to="/topics" style={{textAlign: 'right'}}>
                            <button className="btn btn-primary" aria-label="Start Learning Now">
                                Access 600+ Resources Instantly!
                                <FontAwesomeIcon icon={faPlay} style={{ marginLeft: '10px' }} />
                            </button>
                        </Link>
                    </div> 
                </main>
            </div>
        </div>
    );
};

export default HomePage;
