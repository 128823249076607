import React, { useState, useEffect, useCallback } from 'react';
import { Card, Col, Row, Button, FormControl, InputGroup } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import API_KEY from '../config';
import AdSense from './AdSense';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowLeft, faArrowRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import '../styles/topicsStyle.css';

import debounce from 'lodash/debounce'; // Import lodash debounce

const Topics = () => {
    const [topics, setTopics] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(24);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [expanded, setExpanded] = useState({});
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const query = params.get('search') || ''; // Get search term from URL query
        setSearchTerm(query);
        fetchTopics(query);
    }, [location.search]);

    const fetchTopics = async (searchTerm) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/topics`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${API_KEY}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            let filteredData = data;
            if (searchTerm.trim().length > 0) {
                filteredData = data.filter(element => element.topic?.toLowerCase().includes(searchTerm.trim().toLowerCase()));
            }
            setTopics(filteredData);
        } catch (error) {
            console.error('Error fetching topics:', error);
        } finally {
            setLoading(false);
        }
    };

    const updateURL = useCallback(debounce((searchTerm) => {
        // Update URL query parameter
        const params = new URLSearchParams(location.search);
        params.set('search', searchTerm);
        navigate(`${location.pathname}?${params.toString()}`);
    }, 1000), [location.pathname, navigate]);

    const indexOfLastTopic = currentPage * itemsPerPage;
    const indexOfFirstTopic = indexOfLastTopic - itemsPerPage;
    const currentTopics = topics.slice(indexOfFirstTopic, indexOfLastTopic);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const toggleExpand = (id) => {
        setExpanded((prevExpanded) => ({
            ...prevExpanded,
            [id]: !prevExpanded[id]
        }));
    };

    const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
        setCurrentPage(1);

        // Debounce URL update
        updateURL(newSearchTerm);

        // Fetch topics immediately
        // fetchTopics(newSearchTerm);
    };

    return (
        <div className="container">
            <Helmet>
                <title>{`Cheatsheet++ - ${searchTerm ? `${searchTerm} Topics` : 'Topics'}`}</title>
                <meta
                    name="description"
                    content={`Discover comprehensive cheat sheets ${searchTerm ? `related to ${searchTerm}` : ''} across a wide range of topics. Enhance your learning with beginner, intermediate, and advanced guides on Cheatsheet++.`}
                />
                <meta
                    name="keywords"
                    content={`cheat sheets, software development, coding tutorials, programming, learning, developer resources, ${searchTerm || 'topics'}`}
                />
                <meta property="og:title" content={`Cheatsheet++ - ${searchTerm ? `${searchTerm} Topics` : 'Topics'}`} />
                <meta
                    property="og:description"
                    content={`Explore cheat sheets ${searchTerm ? `related to ${searchTerm}` : ''} and deepen your knowledge with resources tailored for developers at every level.`}
                />
                <meta property="og:image" content="/og-topics-image.jpg" />
                <meta property="og:url" content={`https://www.cheatsheet-plus-plus.com${location.pathname}${location.search}`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`Cheatsheet++ - ${searchTerm ? `${searchTerm} Topics` : 'Topics'}`} />
                <meta
                    name="twitter:description"
                    content={`Discover cheat sheets ${searchTerm ? `related to ${searchTerm}` : ''} across various software development topics. Learn and grow with Cheatsheet++.`}
                />
                <meta name="twitter:image" content="/twitter-topics-image.jpg" />
                
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6060537356298204"
                    crossOrigin="anonymous"></script>
            </Helmet>
            
            <div>
                {/* <br />
                <AdSense adClient="ca-pub-6060537356298204" adSlot="9925678904" adFormat="auto" /> */}
                <div style={{ margin: 'auto'}}>
                    <div style={{ textAlign: 'center', marginTop: '40px' }}>
                        <h3 style={{ color: '#007bff' }}>Discover over 600+ Cheat Sheets.</h3>
                        <p style={{ color: '#555' }}>From beginner tips to expert-level tricks.</p>
                    </div>

                    <InputGroup className="mb-3 mt-5" 
                        style={{ maxWidth: '600px', marginTop: '0px' }}
                    >
                        <InputGroup.Text style={{ backgroundColor: '#f8f9fa', border: '0px #ced4da', borderRadius: '0.375rem' }}>
                            <FontAwesomeIcon icon={faSearch} size="lg" color="#007bff" />
                        </InputGroup.Text>
                        <FormControl className='topicsForm'
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </InputGroup>

                </div>

                {loading ? (
                    <div className="spinner-container">
                        <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                    </div>
                ) : (
                    <>
                        {currentTopics.length > 2 && (
                            <div className="pagination" style={{ marginTop: '0px', display: 'flex', justifyContent: 'center' }}>
                                <Button className='navigationPrevious'
                                    disabled={currentPage === 1}
                                    onClick={() => paginate(currentPage - 1)}
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} size="sm" />  Previous
                                </Button>

                                <Button className='navigationNext'
                                    disabled={indexOfLastTopic >= topics.length}
                                    onClick={() => paginate(currentPage + 1)}
                                >
                                    Next  <FontAwesomeIcon icon={faArrowRight} size="sm" />
                                </Button>
                            </div>
                        )}

                        <Row xs={2} md={4} lg={6} className="g-4" margin="small" style={{ margin: "5px" }}>
                            {currentTopics.map((item) => (
                                <Col key={item._id} style={{ padding: "5px", marginTop: "5px" }}>
                                    <Link to={`/topics/${encodeURIComponent(item.topic)}`} style={{ textDecoration: 'none' }}>
                                        <Card
                                            bg="lighter"
                                            text="black"
                                            className="card-hover rounded topicCard"
                                        >
                                            <Card.Body className="border-primary rounded" style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '0px' }}>
                                                <Card.Title style={{ color: '#003860', fontSize: '1rem' }}>
                                                    {item.topic}
                                                </Card.Title>
                                                <Card.Text className='cardText'>
                                                    {expanded[item._id] ? item.description : `${item.description?.substring(0, 100)}...`}
                                                    <Button
                                                        variant="link"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            toggleExpand(item._id);
                                                        }}
                                                        style={{ padding: '0', marginLeft: '5px', fontSize: 'smaller' }}
                                                    >
                                                        {expanded[item._id] ? 'less' : 'more'}
                                                    </Button>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                        <div className="pagination" style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                            <Button
                                disabled={currentPage === 1}
                                onClick={() => paginate(currentPage - 1)}
                                style={{ marginRight: 10, padding: '5px 15px', fontSize: '1rem', backgroundColor: '#00518F', border: 'none', width: '120px'}}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} size="sm" />  Previous
                            </Button>

                            <Button
                                disabled={indexOfLastTopic >= topics.length}
                                onClick={() => paginate(currentPage + 1)}
                                style={{ marginLeft: 10, padding: '5px 15px', fontSize: '1rem', backgroundColor: '#00518F', border: 'none', width: '120px'}}
                            >
                                Next  <FontAwesomeIcon icon={faArrowRight} size="sm" />
                            </Button>
                        </div>
                    </>
                )}
                <br />
                <span style={{ fontSize: '10px', color: 'grey'}}>advertisement</span>
                <AdSense adClient="ca-pub-6060537356298204" adSlot="2399011869" adFormat="auto" />
            </div>
        </div>
    );
};

export default Topics;
