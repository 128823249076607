import React, { useEffect, useRef } from 'react';
import ForceGraph3D from '3d-force-graph';
import * as THREE from 'three';
import SpriteText from 'three-spritetext'; // Import SpriteText from the correct package

const TechGraph = () => {
  const graphRef = useRef(null);

  useEffect(() => {
    const graphData = {
      "nodes": [
    { "id": "JavaScript", "group": "Technology" },
    { "id": "Python", "group": "Technology" },
    { "id": "Java", "group": "Technology" },
    { "id": "C#", "group": "Technology" },
    { "id": "PHP", "group": "Technology" },
    { "id": "HTML", "group": "Technology" },
    { "id": "CSS", "group": "Technology" },
    { "id": "ReactJS", "group": "Technology" },
    { "id": "Node.js", "group": "Technology" },
    { "id": "SQL", "group": "Technology" },
    { "id": "MySQL", "group": "Technology" },
    { "id": "TypeScript", "group": "Technology" },
    { "id": "Angular", "group": "Technology" },
    { "id": "SQL Server", "group": "Technology" },
    { "id": "Django", "group": "Technology" },
    { "id": "Ruby", "group": "Technology" },
    { "id": "Ruby on Rails", "group": "Technology" },
    { "id": "ASP.NET", "group": "Technology" },
    { "id": "Swift", "group": "Technology" },
    { "id": "Kotlin", "group": "Technology" },
    { "id": "Docker", "group": "Technology" },
    { "id": "Git", "group": "Technology" },
    { "id": "GitHub", "group": "Technology" },
    { "id": "Linux", "group": "Technology" },
    { "id": "Android", "group": "Technology" },
    { "id": "iOS", "group": "Technology" },
    { "id": "Vue.js", "group": "Technology" },
    { "id": "MongoDB", "group": "Technology" },
    { "id": "Postgres", "group": "Technology" },
    { "id": "Azure", "group": "Technology" },
    { "id": "AWS", "group": "Technology" },
    { "id": "Firebase", "group": "Technology" },
    { "id": "REST", "group": "Technology" },
    { "id": "Nginx", "group": "Technology" },
    { "id": "Selenium", "group": "Technology" },
    { "id": "JSON", "group": "Technology" },
    { "id": "Apache", "group": "Technology" },
    { "id": "Kubernetes", "group": "Technology" },
    { "id": "TensorFlow", "group": "Technology" },
    { "id": "GraphQL", "group": "Technology" },
    { "id": "Jenkins", "group": "Technology" },
    { "id": "Redis", "group": "Technology" },
    { "id": "Flask", "group": "Technology" },
    { "id": "Express", "group": "Technology" },
    { "id": "Unity Game Engine", "group": "Technology" },
    { "id": "Elasticsearch", "group": "Technology" },
    { "id": "Jupyter", "group": "Technology" },
    { "id": "Webpack", "group": "Technology" },
    { "id": "Machine Learning", "group": "Technology" },
    { "id": "C++", "group": "Technology" },
    { "id": "spring-boot", "group": "Technology" },
    { "id": "API", "group": "Technology" },
    { "id": "web-services", "group": "Technology" },
    { "id": "postman", "group": "Technology" },
    { "id": "swagger", "group": "Technology" },

    { "id": "Xcode", "group": "IDE" },
    { "id": "Cocoa Touch", "group": "Framework" },
    { "id": "Objective-C", "group": "Language" },
    { "id": "UIKit", "group": "Framework" },
    { "id": "Core Data", "group": "Framework" },
    { "id": "ARKit", "group": "Framework" },

    { "id": "jQuery", "group": "Library" },
    { "id": "Bootstrap", "group": "CSS Framework" },
    { "id": "Sass", "group": "CSS Preprocessor" },
    { "id": "SVG", "group": "Graphics" },
    { "id": "Responsive Web Design", "group": "Technique" },

    { "id": "heroku", "group": "Technology" },
    { "id": ".net", "group": "Technology" },
    { "id": "entity-framework", "group": "Technology" },
    { "id": "gitlab", "group": "Technology" },
    { "id": "google-cloud-platform", "group": "Technology" },
  ],
  "links": [
    { "source": "JavaScript", "target": "ReactJS" },
    { "source": "JavaScript", "target": "Node.js" },
    { "source": "HTML", "target": "CSS" },
    { "source": "Python", "target": "Django" },
    { "source": "Python", "target": "Flask" },
    { "source": "Java", "target": "spring-boot" },
    { "source": "C#", "target": "ASP.NET" },
    { "source": "Node.js", "target": "MongoDB" },
    { "source": "JavaScript", "target": "Postgres" },
    { "source": "Python", "target": "Postgres" },
    { "source": "Java", "target": "SQL Server" },

    { "source": "SQL", "target": "Postgres" },
    { "source": "SQL", "target": "SQL Server" },
    { "source": "SQL", "target": "MySQL" },

    { "source": "Python", "target": "Jupyter" },

    { "source": "API", "target": "web-services" },
    { "source": "API", "target": "postman" },
    { "source": "API", "target": "swagger" },
    { "source": "API", "target": "REST" },

    { "source": "PHP", "target": "MySQL" },
    { "source": "AWS", "target": "Docker" },
    { "source": "AWS", "target": "Kubernetes" },
    { "source": "Azure", "target": "Docker" },
    { "source": "Azure", "target": "Kubernetes" },
    { "source": "Firebase", "target": "Android" },
    { "source": "Git", "target": "GitHub" },
    { "source": "Jenkins", "target": "Docker" },
    { "source": "Docker", "target": "Kubernetes" },
    { "source": "Vue.js", "target": "JavaScript" },
    { "source": "ReactJS", "target": "Node.js" },
    { "source": "Angular", "target": "TypeScript" },
    { "source": "Android", "target": "Kotlin" },
    { "source": "iOS", "target": "Swift" },
    { "source": "Unity Game Engine", "target": "C#" },
    { "source": "Selenium", "target": "Java" },
    { "source": "GraphQL", "target": "JavaScript" },
    { "source": "Machine Learning", "target": "Python" },
    { "source": "TensorFlow", "target": "Python" },
    { "source": "Express", "target": "Node.js" },
    { "source": "C++", "target": "Unity Game Engine" },

    { "source": "iOS", "target": "Swift" },
    { "source": "iOS", "target": "Xcode" },
    { "source": "iOS", "target": "Cocoa Touch" },
    { "source": "iOS", "target": "Objective-C" },
    { "source": "iOS", "target": "UIKit" },
    { "source": "iOS", "target": "Core Data" },
    { "source": "iOS", "target": "ARKit" },
    { "source": "Xcode", "target": "Swift" },
    { "source": "Objective-C", "target": "Xcode" },

    { "source": "HTML", "target": "CSS" },
    { "source": "HTML", "target": "JavaScript" },
    { "source": "HTML", "target": "SVG" },
    { "source": "HTML", "target": "Bootstrap" },
    { "source": "CSS", "target": "Bootstrap" },
    { "source": "CSS", "target": "Sass" },
    { "source": "HTML", "target": "Responsive Web Design" },
    { "source": "CSS", "target": "Responsive Web Design" },
    { "source": "JavaScript", "target": "jQuery" },
    { "source": "HTML", "target": "Angular" },
    { "source": "HTML", "target": "ReactJS" },

    { "source": "JSON", "target": "JavaScript" },
    { "source": "JSON", "target": "Node.js" },
    { "source": "JSON", "target": "REST" },
    { "source": "JSON", "target": "API" },
    { "source": "JSON", "target": "Express" },
    { "source": "JSON", "target": "Python" },
    { "source": "JSON", "target": "Java" },
    { "source": "JSON", "target": "Flask" },
    { "source": "JSON", "target": "postman" },
    { "source": "JSON", "target": "MongoDB" },

    { "source": "Apache", "target": "Nginx" },
    { "source": "Apache", "target": "PHP" },
    { "source": "Apache", "target": "MySQL" },
    { "source": "Apache", "target": "REST" },
    { "source": "Apache", "target": "Docker" },
    { "source": "Apache", "target": "Linux" },
    { "source": "Apache", "target": "Postgres" },

    { "source": "Webpack", "target": "JavaScript" },
    { "source": "Webpack", "target": "ReactJS" },
    { "source": "Webpack", "target": "Vue.js" },
    { "source": "Webpack", "target": "Node.js" },
    { "source": "Webpack", "target": "TypeScript" },
    { "source": "Webpack", "target": "CSS" },
    { "source": "Webpack", "target": "Sass" },
    { "source": "Webpack", "target": "REST" },
    { "source": "Webpack", "target": "Docker" },

    { "source": "Ruby on Rails", "target": "Ruby" },
    { "source": "Ruby on Rails", "target": "Postgres" },
    { "source": "Ruby on Rails", "target": "MySQL" },
    { "source": "Ruby on Rails", "target": "REST" },
    { "source": "Ruby on Rails", "target": "JSON" },
    { "source": "Ruby on Rails", "target": "Docker" },
    { "source": "Ruby on Rails", "target": "Linux" },
    { "source": "Ruby on Rails", "target": "Nginx" },
    { "source": "Ruby on Rails", "target": "heroku" },
    { "source": "Ruby on Rails", "target": "Jenkins" },

    { "source": "C#", "target": ".net" },
    { "source": "C#", "target": "ASP.NET" },
    { "source": "C#", "target": "SQL Server" },
    { "source": "C#", "target": "entity-framework" },
    { "source": "C#", "target": "Azure" },
    { "source": "C#", "target": "Docker" },
    { "source": "C#", "target": "JSON" },
    { "source": "C#", "target": "REST" },

    { "source": "Git", "target": "Docker" },
    { "source": "Git", "target": "Jenkins" },
    { "source": "Git", "target": "Azure" },
    { "source": "Git", "target": "AWS" },
    { "source": "Git", "target": "Node.js" },
    { "source": "Git", "target": "ReactJS" },
    { "source": "Git", "target": "Python" },
    { "source": "GitHub", "target": "gitlab" },
    { "source": "GitHub", "target": "Git" },

    { "source": "Elasticsearch", "target": "Node.js" },
    { "source": "Elasticsearch", "target": "Java" },
    { "source": "Elasticsearch", "target": "Python" },
    { "source": "Elasticsearch", "target": "REST" },
    { "source": "Elasticsearch", "target": "MongoDB" },
    { "source": "Elasticsearch", "target": "Docker" },
    { "source": "Elasticsearch", "target": "AWS" },
    { "source": "Elasticsearch", "target": "Kubernetes" },

    { "source": "Firebase", "target": "Node.js" },
    { "source": "Firebase", "target": "google-cloud-platform" },
    { "source": "Firebase", "target": "Docker" },
    { "source": "Firebase", "target": "AWS" },
    { "source": "Android", "target": "Java" },
    { "source": "Kotlin", "target": "Java" },
    { "source": "Firebase", "target": "REST" },
    { "source": "Android", "target": "REST" },
    { "source": "Firebase", "target": "Git" },
    { "source": "Firebase", "target": "Jenkins" },

    { "source": "Redis", "target": "AWS" },
    { "source": "Redis", "target": "Postgres" },
    { "source": "Redis", "target": "REST" },

    { "source": "iOS", "target": "Firebase" },
    { "source": "iOS", "target": "REST" },
    { "source": "iOS", "target": "JSON" },
    { "source": "iOS", "target": "AWS" },
    { "source": "Xcode", "target": "Jenkins" },
    { "source": "Swift", "target": "JSON" },
    { "source": "Objective-C", "target": "JSON" }
  ]
    };

    const Graph = ForceGraph3D()(graphRef.current)
        .graphData(graphData)
        .nodeAutoColorBy('group')
        .linkDirectionalParticles(2)
        .linkDirectionalParticleSpeed(d => d.value * 0.0001)
        .onNodeClick(node => window.location.href = `/topics/${node.id.toLowerCase()}`)
        .nodeThreeObject(node => {
        const sprite = new SpriteText(node.id);
            sprite.color = 'white';  // Set text color
            sprite.textHeight = 8;   // Set text size
            return sprite;
        })
        .width(graphRef.current.clientWidth)
        .height(graphRef.current.clientHeight)
        .linkWidth(1)
        .linkColor("#fff")
        .backgroundColor('#00518F')
        .cameraPosition({ z: 200 })
        
        // .onEngineTick(() => {
        //     const t = Date.now() * 0.0001;  // Time-based function to smoothly rotate
        //     const camera = Graph.camera();  // Access the camera
        //     camera.position.x = 200 * Math.sin(t);
        //     camera.position.z = 200 * Math.cos(t);
        //     camera.lookAt(Graph.scene().position);  // Keep the camera focused on the center of the graph
        //   });
  }, []);

  return (
    <div>
      <div ref={graphRef} style={{ height: '400px' }} />
    </div>
  );
}

export default TechGraph;
